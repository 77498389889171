/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Image from "next/image";

function ImageGallery() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className="overflow-hidden relative">
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        slidesPerGroup={1}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay={{
          delay: 4500,
          disableOnInteraction: true,
        }}
        modules={[Navigation, Autoplay]}
        onSwiper={(swiper) => {
          swiper.navigation.update();
        }}
        breakpoints={{
          100: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
        className="mySwiper"
      >
        {/* Repeat this SwiperSlide structure for each slide you want to include */}
        <SwiperSlide>
          <div>
            <div className="flex flex-col flex-shrink justify-center items-center">
              <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
                <div>
                  <Image
                    className="no-drag"
                    src="/home/worknook.webp"
                    alt="Image"
                    width={260}
                    height={260}
                  />
                </div>
                <div className="flex flex-row justify-start items-center pl-2 pt-2">
                  <Image
                    className="rounded-full no-drag"
                    src="/home/usericons/DaphneSunflower.png"
                    alt="Image"
                    width={20}
                    height={20}
                  />
                  <div className="pl-2 text-xs">
                    <h3>Hailey Jackson</h3>
                    <h4>@HaileyJayJay</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col flex-shrink justify-center items-center">
            <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
              {" "}
              <div>
                <Image
                  className="no-drag"
                  src="/home/breakfastnook.webp"
                  alt="Image"
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-row justify-start items-center pl-2 pt-2">
                <Image
                  className="rounded-full no-drag"
                  src="/home/usericons/AlexRiviera.png"
                  alt="Image"
                  width={20}
                  height={20}
                />
                <div className="pl-2 text-xs">
                  <h3>Alex Riviera</h3>
                  <h4>@rivera_alex98</h4>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col flex-shrink justify-center items-center">
            <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
              {" "}
              <div>
                <Image
                  className="no-drag"
                  src="/home/browncouch.webp"
                  alt="Image"
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-row justify-start items-center pl-2 pt-2">
                <Image
                  className="rounded-full no-drag"
                  src="/home/usericons/EthanKowalski.png"
                  alt="Image"
                  width={20}
                  height={20}
                />
                <div className="pl-2 text-xs">
                  <h3>Ethan Kowalski</h3>
                  <h4>@kowalski_ethan</h4>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col flex-shrink justify-center items-center">
            <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
              {" "}
              <div>
                <Image
                  className="no-drag"
                  src="/home/cowboy.webp"
                  alt="Image"
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-row justify-start items-center pl-2 pt-2">
                <Image
                  className="rounded-full no-drag"
                  src="/home/usericons/PeterEkhart.png"
                  alt="Image"
                  width={20}
                  height={20}
                />
                <div className="pl-2 text-xs">
                  <h3>Peter Eckhart</h3>
                  <h4>@peteofmymind</h4>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col flex-shrink justify-center items-center">
            <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
              {" "}
              <div>
                <Image
                  className="no-drag"
                  src="/home/beachabstract.webp"
                  alt="Image"
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-row justify-start items-center pl-2 pt-2">
                <Image
                  className="rounded-full no-drag"
                  src="/home/usericons/BellaZhang.png"
                  alt="Image"
                  width={20}
                  height={20}
                />
                <div className="pl-2 text-xs">
                  <h3>Bella Zhang</h3>
                  <h4>@bellaz_hangs</h4>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col flex-shrink justify-center items-center">
            <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
              {" "}
              <div>
                <Image
                  className="no-drag"
                  src="/home/fieldclouds.webp"
                  alt="Image"
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-row justify-start items-center pl-2 pt-2">
                <Image
                  className="rounded-full no-drag"
                  src="/home/usericons/JasmineEl-Khoury.png"
                  alt="Image"
                  width={20}
                  height={20}
                />
                <div className="pl-2 text-xs">
                  <h3>Jasmine El-Khoury</h3>
                  <h4>@jasmineelk_beauty</h4>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col flex-shrink justify-center items-center">
            <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
              {" "}
              <div>
                <Image
                  className="no-drag"
                  src="/home/flowerscouch.webp"
                  alt="Image"
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-row justify-start items-center pl-2 pt-2">
                <Image
                  className="rounded-full no-drag"
                  src="/home/usericons/FionaChen.png"
                  alt="Image"
                  width={20}
                  height={20}
                />
                <div className="pl-2 text-xs">
                  <h3>Fiona Chen</h3>
                  <h4>@fionachen_loves</h4>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex flex-col flex-shrink justify-center items-center">
            <div className="border border-grey-600 pb-8 shadow-lg min-w-[100px]">
              {" "}
              <div>
                <Image
                  className="no-drag"
                  src="/home/kidsroom.webp"
                  alt="Image"
                  width={260}
                  height={260}
                />
              </div>
              <div className="flex flex-row justify-start items-center pl-2 pt-2">
                <Image
                  className="rounded-full no-drag"
                  src="/home/usericons/IsaacThornton.png"
                  alt="Image"
                  width={20}
                  height={20}
                />
                <div className="pl-2 text-xs">
                  <h3>Isaac Thornton</h3>
                  <h4>@thornton_isaac_views</h4>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      {/* Custom navigation buttons */}
      <button
        ref={navigationPrevRef}
        className="custom-swiper-button-next absolute top-1/2 left-0 ml-2 transform -translate-y-1/2 p-1 z-10 border border-black rounded-full"
      >
        <ArrowBackIosNewIcon className="flex justify-center items-center text-base" />
      </button>
      <button
        ref={navigationNextRef}
        className="custom-swiper-button-next absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 p-1 z-10 border border-black rounded-full"
      >
        <ArrowForwardIosIcon className="flex justify-center items-center text-base" />
      </button>
    </div>
  );
}

export default ImageGallery;
